<template>
  <div id="app">
    <div class="d-print-none">
<!--    <router-link :to="{ name: 'Hello' }">Home</router-link>-->
<!--    <router-link to="/about">About</router-link>-->
    </div>
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>

html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
#app {

}
</style>
